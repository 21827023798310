import React from 'react';
import { strapiUrl } from '../../../../utils/constants';
import DownloadFile from '../Elements/DownloadFile';

const buttonLabels = {
  curriculumVitae: 'Curriculum Vitae',
  extendedCitation: 'Extended Citation',
  significantPublications: 'Significant Publications',
  publications: 'Publications',
};

const RefereeTitle = () => {
  const refereeReportString = sessionStorage.getItem('refereeReport');

  if (!refereeReportString) {
    return null;
  }

  const refereeReport = JSON.parse(refereeReportString);

  return (
    <div className="col-lg-12">
      <div className="form-group" />
      <h1 className="mb-xl-3">
        {refereeReport.candidate.attributes.name}{' '}
        {refereeReport.candidate.attributes.lastName}
      </h1>
      <span className="badge badge-info m-1">
        {refereeReport.refereeStatus?.attributes.name || ''}
      </span>

      <span className="badge badge-primary m-1">
        {refereeReport.electionType?.attributes.name || ''}
      </span>

      <h3 className="mt-xl-4">Short citation</h3>
      <p>{refereeReport.nominationState[0]?.attributes.citation || ''}</p>

      <h3 className="mt-xl-5 mb-xl-3">Download documentation</h3>
      <div className="documentation mb-xl-5">
        {Object.keys(buttonLabels).map(
          (label) =>
            refereeReport.nominationState[0]?.attributes?.[label]?.data && (
              <DownloadFile
                key={label}
                text={buttonLabels[label]}
                url={`${strapiUrl}${refereeReport.nominationState[0].attributes[label].data.attributes.url}`}
                name={label}
                buttonClassName="btn btn-outline-primary sw-btn-next ms-1 mt-1 me-4"
                showFileName={false}
              />
            ),
        )}
      </div>
    </div>
  );
};

export default RefereeTitle;
