export const checkPermissionAndNavigate = (
  pathname,
  userDetailsObject,
  navigate,
) => {
  const restrictedPaths = [
    {
      path: '/nomination-administration',
      allowedRoles: ['admin', 'super-admin'],
    },
    {
      path: '/assessor-administration',
      allowedRoles: ['admin', 'super-admin'],
    },
    {
      path: '/chair-committees',
      allowedRoles: ['admin', 'super-admin'],
    },
    {
      path: '/elect-candidate',
      allowedRoles: ['admin', 'super-admin'],
    },
    {
      path: '/nominations',
      allowedRoles: ['fellow', 'super-admin'],
    },
    {
      path: '/nomination-shortlisting',
      allowedRoles: ['admin', 'chair', 'super-admin'],
    },
    {
      path: '/pre-nomination-steps',
      allowedRoles: ['super-admin'],
    },
    {
      path: '/referee',
      allowedRoles: ['super-admin', 'referee'],
    },
    {
      path: '/referee-administration',
      allowedRoles: ['admin', 'super-admin'],
    },
    {
      path: '/settings',
      allowedRoles: ['super-admin'],
    },
    {
      path: '/masquerade',
      allowedRoles: ['admin', 'super-admin'],
    },
    {
      path: '/application',
      allowedRoles: ['fellow', 'super-admin'],
    },
  ];

  const isPathRestricted = restrictedPaths.some(
    (item) => item.path === pathname,
  );

  if (isPathRestricted) {
    const { allowedRoles } = restrictedPaths.find(
      (item) => item.path === pathname,
    );
    const hasAccess = allowedRoles.some((role) =>
      userDetailsObject.roles.includes(role),
    );

    const allowedRoute = restrictedPaths.find((item) =>
      item.allowedRoles.some((value) =>
        userDetailsObject.roles.includes(value),
      ),
    );

    const allowedPath = allowedRoute ? allowedRoute.path : '/permission-denied';

    if (!hasAccess) {
      navigate(allowedPath);
    }
  }
};
