import { axiosGet } from './axiosGet';

export const getUserRoles = async (user) => {
  const { id, fellowId } = user;

  const roles = ['default'];
  try {
    if (id) {
      const refereeResult = await axiosGet(
        `referee-reports?filters[refereeUserId][$eq]=${id}&filters[refereeArchived][$eq]=false`,
      );

      if (refereeResult && refereeResult.data.data.length !== 0) {
        roles.push('referee');
      }
      const adminResult = await axiosGet(
        `admins?filters[users_permissions_user][id][$eq]=${id}&populate=*`,
      );

      if (adminResult && adminResult.data.data.length !== 0) {
        roles.push('admin');
      }

      const superAdminResult = await axiosGet(
        `super-admins?filters[users_permissions_user][id][$eq]=${id}&populate=*`,
      );

      if (superAdminResult && superAdminResult.data.data.length !== 0) {
        roles.push('super-admin');
      }
    }

    if (fellowId) {
      const fellowResult = await axiosGet(
        `fellows?filters[id][$eq]=${fellowId}`,
      );
      if (fellowResult && fellowResult.data.data.length !== 0) {
        roles.push('fellow');
      }

      const chairResult = await axiosGet(
        `chair-committees?filters[fellowId][$eq]=${fellowId}`,
      );
      if (chairResult && chairResult.data.data.length !== 0) {
        roles.push('chair');
      }
    }
  } catch (error) {
    console.error('Error checking user roles:', error);
    return false;
  }

  // Return the list of roles
  return roles;
};
