import countryList from 'react-select-country-list';
import { strapiUrl } from './constants';
import { axiosGet } from './axiosGet';
import { axiosPut } from './axiosPut';

export const getNominationFormData = async (nominationData, organisations) => {
  try {
    const response = await axiosGet('get-nomination-form-data', {
      data: nominationData,
    });

    if (response?.data) {
      const {
        nomination,
        candidate,
        nominationDeclaration,
        candidateState,
        nominationState,
        proposerSeconder,
        nominationSupporter,
        nominationReferee,
      } = response.data;

      const countryLabel = candidate.countryOfBirthId
        ? countryList().getLabel(candidate.countryOfBirthId)
        : '';

      const nominationFormData = {
        nominationStatusTypeId:
          typeof nomination?.attributes?.nominationStatusTypeId === 'string'
            ? nomination.attributes.nominationStatusTypeId
            : null,

        electionTypeId:
          typeof nomination?.attributes?.electionTypeId === 'string'
            ? nomination.attributes.electionTypeId
            : null,

        consideredYears:
          typeof candidateState?.attributes?.consideredYears === 'string'
            ? candidateState?.attributes?.consideredYears
            : null,

        titleId:
          typeof candidate?.titleId === 'number' ? candidate.titleId : null,

        name: typeof candidate?.name === 'string' ? candidate.name : null,

        lastName:
          typeof candidate?.lastName === 'string' ? candidate.lastName : null,

        phone: typeof candidate?.phone === 'string' ? candidate.phone : null,

        address:
          typeof candidate?.address === 'string' ? candidate.address : null,

        email: typeof candidate?.email === 'string' ? candidate.email : null,

        stateTerritoryId:
          typeof candidate?.stateTerritoryId === 'number'
            ? candidate.stateTerritoryId
            : null,

        genderId:
          typeof candidate?.genderId === 'number' ? candidate.genderId : null,
        otherGender:
          typeof candidate?.otherGender === 'string'
            ? candidate.otherGender
            : null,

        dob: typeof candidate?.dob === 'string' ? candidate.dob : null,

        countryOfBirthId:
          typeof candidate?.countryOfBirthId === 'string' &&
          candidate.countryOfBirthId.length > 0 &&
          countryLabel
            ? {
                value: candidate.countryOfBirthId,
                label: countryLabel,
              }
            : null,

        australianStatusId:
          typeof candidate?.australianStatusId === 'number'
            ? candidate.australianStatusId
            : null,

        postNominal:
          typeof candidate?.postNominal === 'string'
            ? candidate.postNominal
            : null,

        jobTitle:
          typeof candidate?.jobTitle === 'string' ? candidate.jobTitle : null,

        organisationId:
          typeof candidate?.organisationId === 'number'
            ? {
                value: candidate.organisationId,
                label: organisations[candidate.organisationId],
              }
            : {},
        otherOrganisation:
          typeof candidate?.otherOrganisation === 'string'
            ? candidate.otherOrganisation
            : null,

        phdAwardedYear:
          typeof candidate?.phdAwardedYear === 'number'
            ? candidate.phdAwardedYear
            : null,

        noPhd: !candidate.phdAwardedYear,

        personalInformation:
          typeof candidate?.personalInformation === 'string'
            ? candidate.personalInformation
            : null,

        diverseBackground:
          typeof candidate?.diverseBackground === 'boolean' &&
          candidate?.diverseBackground
            ? 1
            : 0,

        nominationConsentFileId: nominationDeclaration?.nominationConsent?.id
          ? {
              id: nominationDeclaration.nominationConsent.id,
            }
          : null,

        nominationConsentFileName: nominationDeclaration?.nominationConsent
          ? nominationDeclaration.nominationConsent.name
          : '',

        nominationConsentFilePath: nominationDeclaration?.nominationConsent
          ? `${strapiUrl}${nominationDeclaration.nominationConsent.url}`
          : '',

        nominationConsent: nominationDeclaration?.nominationConsent?.id
          ? {
              id: nominationDeclaration.nominationConsent.id,
            }
          : null,

        retractedPapers: !!candidateState.retractedPapers,

        correctedPapers: !!candidateState.correctedPapers,

        publicationConcerns: !!candidateState.publicationConcerns,

        disclosureDeclarationFileId:
          nominationDeclaration?.disclosureDeclaration
            ? {
                id: nominationDeclaration.disclosureDeclaration.id,
              }
            : null,

        disclosureDeclarationFileName:
          nominationDeclaration?.disclosureDeclaration
            ? nominationDeclaration.disclosureDeclaration.name
            : '',

        disclosureDeclarationFilePath:
          nominationDeclaration?.disclosureDeclaration
            ? `${strapiUrl}${nominationDeclaration.disclosureDeclaration.url}`
            : '',

        disclosureDeclaration: nominationDeclaration?.disclosureDeclaration
          ? {
              id: nominationDeclaration.disclosureDeclaration.id,
            }
          : null,

        ...(candidate.nationalityId != null && {
          nationalityId: candidate.nationalityId,
        }),

        emailEducationalInstitution: '',
        ...(candidate.arrivalDate != null && {
          arrivalDate: candidate.arrivalDate,
        }),

        careerInterruption: candidateState.careerInterruption ? 1 : 0,

        scientificExcellence: nominationState.scientificExcellence,
        ...(nomination.attributes.electionTypeId === '1' && {
          correspondingElection: {
            value: 1,
            label: 'Corresponding Member and Special Election Committee',
          },
        }),

        citation: nominationState?.citation ? nominationState.citation : '',

        sectionalCommitteeId: nomination.attributes.sectionalCommitteeId,

        deprecatedSectionalCommitteeLabel:
          nomination.attributes.deprecatedSectionalCommitteeLabel ?? null,

        disciplineDescriptor: nomination.attributes.disciplineDescriptor,

        overlapCommittee:
          typeof nomination?.attributes?.overlapCommittee === 'boolean' &&
          nomination?.attributes?.overlapCommittee
            ? 1
            : 0,

        overlapSectionalCommitteeId:
          nomination.attributes.overlapSectionalCommitteeId,

        overlapDisciplineDescriptor:
          nomination.attributes.overlapDisciplineDescriptor,

        interdisciplinaryCommittee:
          nomination.attributes.interdisciplinaryCommittee,

        proposerFellowId:
          typeof proposerSeconder === 'object' &&
          proposerSeconder?.proposerFellowId &&
          proposerSeconder?.proposerFellowLabel
            ? {
                value: proposerSeconder.proposerFellowId,
                label: proposerSeconder.proposerFellowLabel,
              }
            : null,

        proposerSignatureFileId:
          typeof proposerSeconder?.proposerSignature === 'object' &&
          proposerSeconder?.proposerSignature?.id
            ? {
                id: proposerSeconder.proposerSignature.id,
              }
            : null,

        proposerSignatureFileName:
          typeof proposerSeconder?.proposerSignature === 'object' &&
          proposerSeconder?.proposerSignature?.name
            ? proposerSeconder.proposerSignature.name
            : '',

        proposerSignatureFilePath: proposerSeconder.proposerSignature
          ? `${strapiUrl}${proposerSeconder.proposerSignature.url}`
          : '',

        proposerSignature:
          typeof proposerSeconder?.proposerSignature === 'object' &&
          proposerSeconder?.proposerSignature?.id
            ? {
                id: proposerSeconder.proposerSignature.id,
              }
            : null,

        seconderFellowId:
          typeof proposerSeconder === 'object' &&
          proposerSeconder?.seconderFellowId &&
          proposerSeconder?.seconderFellowLabel
            ? {
                value: proposerSeconder.seconderFellowId,
                label: proposerSeconder.seconderFellowLabel,
              }
            : null,

        seconderSignatureFileId:
          typeof proposerSeconder?.seconderSignature === 'object' &&
          proposerSeconder?.seconderSignature?.id
            ? {
                id: proposerSeconder.seconderSignature.id,
              }
            : null,

        seconderSignatureFileName:
          typeof proposerSeconder?.seconderSignature === 'object' &&
          proposerSeconder?.seconderSignature?.name
            ? proposerSeconder.seconderSignature.name
            : '',

        seconderSignatureFilePath:
          typeof proposerSeconder?.seconderSignature === 'object' &&
          proposerSeconder?.seconderSignature?.url
            ? `${strapiUrl}${proposerSeconder.seconderSignature.url}`
            : '',

        seconderSignature:
          typeof proposerSeconder?.seconderSignature === 'object' &&
          proposerSeconder?.seconderSignature?.id
            ? {
                id: proposerSeconder.seconderSignature.id,
              }
            : null,

        supporterOneTypeId: nominationSupporter?.supporterOneTypeId
          ? nominationSupporter.supporterOneTypeId
          : '',

        supporterOneFellowId:
          typeof nominationSupporter?.supporterOneFellow === 'object' &&
          nominationSupporter?.supporterOneFellow?.id &&
          nominationSupporter?.supporterOneFellow?.label
            ? {
                value: nominationSupporter.supporterOneFellow.id,
                label: nominationSupporter.supporterOneFellow.label,
              }
            : null,

        supporterOneSignatureFileId:
          typeof nominationSupporter?.supporterOneSignature === 'object' &&
          nominationSupporter?.supporterOneSignature?.id
            ? {
                id: nominationSupporter.supporterOneSignature.id,
              }
            : null,
        supporterOneSignatureFileName:
          typeof nominationSupporter?.supporterOneSignature === 'object' &&
          nominationSupporter?.supporterOneSignature?.name &&
          nominationSupporter?.supporterOneSignature?.id
            ? nominationSupporter.supporterOneSignature.name
            : '',
        supporterOneSignatureFilePath:
          typeof nominationSupporter?.supporterOneSignature === 'object' &&
          nominationSupporter?.supporterOneSignature?.url
            ? `${strapiUrl}${nominationSupporter.supporterOneSignature.url}`
            : '',
        supporterOneSignature:
          typeof nominationSupporter?.supporterOneSignature === 'object' &&
          nominationSupporter?.supporterOneSignature?.id
            ? {
                id: nominationSupporter.supporterOneSignature.id,
              }
            : null,
        supporterTwoTypeId: nominationSupporter?.supporterTwoTypeId
          ? nominationSupporter.supporterTwoTypeId
          : '',
        supporterTwoFellowId:
          typeof nominationSupporter?.supporterTwoFellow === 'object' &&
          nominationSupporter?.supporterTwoFellow?.id &&
          nominationSupporter?.supporterTwoFellow?.label
            ? {
                value: nominationSupporter.supporterTwoFellow.id,
                label: nominationSupporter.supporterTwoFellow.label,
              }
            : null,

        supporterTwoSignatureFileId:
          typeof nominationSupporter?.supporterTwoSignature === 'object' &&
          nominationSupporter?.supporterTwoSignature?.id
            ? {
                id: nominationSupporter.supporterTwoSignature.id,
              }
            : null,

        supporterTwoSignatureFileName:
          typeof nominationSupporter?.supporterTwoSignature === 'object' &&
          nominationSupporter?.supporterTwoSignature?.name &&
          nominationSupporter?.supporterTwoSignature?.id
            ? nominationSupporter.supporterTwoSignature.name
            : '',

        supporterTwoSignatureFilePath:
          typeof nominationSupporter?.supporterTwoSignature === 'object' &&
          nominationSupporter?.supporterTwoSignature?.url
            ? `${strapiUrl}${nominationSupporter.supporterTwoSignature.url}`
            : '',

        supporterTwoSignature:
          typeof nominationSupporter?.supporterTwoSignature === 'object' &&
          nominationSupporter?.supporterTwoSignature?.id
            ? {
                id: nominationSupporter.supporterTwoSignature.id,
              }
            : null,

        supporterThreeTypeId: nominationSupporter?.supporterThreeTypeId
          ? nominationSupporter.supporterThreeTypeId
          : '',

        supporterThreeFellowId:
          typeof nominationSupporter?.supporterThreeFellow === 'object' &&
          typeof nominationSupporter?.supporterThreeFellow?.id === 'number' &&
          nominationSupporter?.supporterThreeFellow?.label
            ? {
                value: nominationSupporter.supporterThreeFellow.id,
                label: nominationSupporter.supporterThreeFellow.label,
              }
            : null,

        supporterThreeSignatureFileId:
          typeof nominationSupporter?.supporterThreeSignature === 'object' &&
          nominationSupporter?.supporterThreeSignature?.id
            ? {
                id: nominationSupporter.supporterThreeSignature.id,
              }
            : null,

        supporterThreeSignatureFileName:
          typeof nominationSupporter?.supporterThreeSignature === 'object' &&
          nominationSupporter?.supporterThreeSignature?.name &&
          nominationSupporter?.supporterThreeSignature?.id
            ? nominationSupporter?.supporterThreeSignature?.name
            : '',

        supporterThreeSignatureFilePath:
          typeof nominationSupporter?.supporterThreeSignature === 'object' &&
          nominationSupporter?.supporterThreeSignature?.url
            ? `${strapiUrl}${nominationSupporter.supporterThreeSignature.url}`
            : '',

        supporterThreeSignature:
          typeof nominationSupporter?.supporterThreeSignature === 'object' &&
          nominationSupporter?.supporterThreeSignature?.id
            ? {
                id: nominationSupporter.supporterThreeSignature.id,
              }
            : null,

        supporterFourTypeId: nominationSupporter?.supporterFourTypeId
          ? nominationSupporter.supporterFourTypeId
          : '',

        supporterFourFellowId:
          typeof nominationSupporter?.supporterFourFellow === 'object' &&
          typeof nominationSupporter?.supporterFourFellow?.id === 'number' &&
          nominationSupporter?.supporterFourFellow?.label
            ? {
                value: nominationSupporter.supporterFourFellow.id,
                label: nominationSupporter.supporterFourFellow.label,
              }
            : null,

        supporterFourSignatureFileId:
          typeof nominationSupporter?.supporterFourSignature === 'object' &&
          nominationSupporter?.supporterFourSignature?.id
            ? {
                id: nominationSupporter.supporterFourSignature.id,
              }
            : null,

        supporterFourSignatureFileName:
          typeof nominationSupporter?.supporterFourSignature === 'object' &&
          nominationSupporter?.supporterFourSignature?.name &&
          nominationSupporter?.supporterFourSignature?.id
            ? nominationSupporter.supporterFourSignature.name
            : '',

        supporterFourSignatureFilePath:
          typeof nominationSupporter?.supporterFourSignature === 'object' &&
          nominationSupporter?.supporterFourSignature?.url
            ? `${strapiUrl}${nominationSupporter.supporterFourSignature.url}`
            : '',

        supporterFourSignature:
          typeof nominationSupporter?.supporterFourSignature === 'object' &&
          nominationSupporter?.supporterFourSignature?.id
            ? {
                id: nominationSupporter.supporterFourSignature.id,
              }
            : null,

        meetsConduct: candidateState?.meetsConduct ? 1 : 0,

        extendedCitationFileId:
          typeof nominationState?.extendedCitation === 'object' &&
          nominationState?.extendedCitation?.id
            ? {
                id: nominationState.extendedCitation.id,
              }
            : null,

        extendedCitationFileName:
          typeof nominationState?.extendedCitation === 'object' &&
          nominationState?.extendedCitation?.name
            ? nominationState.extendedCitation.name
            : '',

        extendedCitationFilePath:
          typeof nominationState?.extendedCitation === 'object' &&
          nominationState?.extendedCitation?.url
            ? `${strapiUrl}${nominationState.extendedCitation.url}`
            : '',

        extendedCitation:
          typeof nominationState?.extendedCitation === 'object' &&
          nominationState?.extendedCitation?.id
            ? {
                id: nominationState.extendedCitation.id,
              }
            : null,

        curriculumVitaeFileId:
          typeof nominationState?.curriculumVitae === 'object' &&
          nominationState?.curriculumVitae?.id
            ? {
                id: nominationState.curriculumVitae.id,
              }
            : null,

        curriculumVitaeFileName:
          typeof nominationState?.curriculumVitae === 'object' &&
          nominationState?.curriculumVitae?.name
            ? nominationState.curriculumVitae.name
            : '',

        curriculumVitaeFilePath:
          typeof nominationState?.curriculumVitae === 'object' &&
          nominationState?.curriculumVitae?.url
            ? `${strapiUrl}${nominationState.curriculumVitae.url}`
            : '',

        curriculumVitae:
          typeof nominationState?.curriculumVitae === 'object' &&
          nominationState?.curriculumVitae?.id
            ? {
                id: nominationState.curriculumVitae.id,
              }
            : null,

        significantPublicationsFileId:
          typeof nominationState?.significantPublications &&
          nominationState?.significantPublications?.id
            ? {
                id: nominationState.significantPublications.id,
              }
            : null,

        significantPublicationsFileName:
          typeof nominationState?.significantPublications === 'object' &&
          nominationState?.significantPublications?.name
            ? nominationState.significantPublications.name
            : '',

        significantPublicationsFilePath:
          typeof nominationState?.significantPublications === 'object' &&
          nominationState?.significantPublications?.url
            ? `${strapiUrl}${nominationState.significantPublications.url}`
            : '',

        significantPublications:
          typeof nominationState?.significantPublications &&
          nominationState?.significantPublications?.id
            ? {
                id: nominationState.significantPublications.id,
              }
            : null,

        publicationsFileId:
          typeof nominationState?.publications &&
          nominationState?.publications?.id
            ? {
                id: nominationState.publications.id,
              }
            : null,

        publicationsFileName:
          typeof nominationState?.publications === 'object' &&
          nominationState?.publications?.name
            ? nominationState?.publications.name
            : '',

        publicationsFilePath:
          typeof nominationState?.publications === 'object' &&
          nominationState?.publications?.url
            ? `${strapiUrl}${nominationState.publications.url}`
            : '',

        publications:
          typeof nominationState?.publications &&
          nominationState?.publications?.id
            ? {
                id: nominationState.publications.id,
              }
            : null,

        refereeOne:
          typeof nominationReferee?.refereeOneTypeId === 'number'
            ? nominationReferee.refereeOneTypeId
            : null,

        refereeTwo:
          typeof nominationReferee?.refereeTwoTypeId === 'number'
            ? nominationReferee.refereeTwoTypeId
            : null,

        refereeThree:
          typeof nominationReferee?.refereeThreeTypeId === 'number'
            ? nominationReferee.refereeThreeTypeId
            : null,

        refereeFour:
          typeof nominationReferee?.refereeFourTypeId === 'number'
            ? nominationReferee.refereeFourTypeId
            : null,

        refereeFive:
          typeof nominationReferee?.refereeFiveTypeId === 'number'
            ? nominationReferee.refereeFiveTypeId
            : null,

        refereeSix:
          typeof nominationReferee?.refereeSixTypeId === 'number'
            ? nominationReferee.refereeSixTypeId
            : null,

        refereeSeven:
          typeof nominationReferee?.refereeSevenTypeId === 'number'
            ? nominationReferee.refereeSevenTypeId
            : null,

        refereeEight:
          typeof nominationReferee?.refereeEightTypeId === 'number'
            ? nominationReferee.refereeEightTypeId
            : null,

        refereeNine:
          typeof nominationReferee?.refereeNineTypeId === 'number'
            ? nominationReferee.refereeNineTypeId
            : null,

        refereeOneFellowId: {
          ...(nominationReferee?.refereeOneTypeId === 1 &&
            nominationReferee?.refereeOneFellow?.id && {
              value: nominationReferee.refereeOneFellow.id,
            }),
          ...(nominationReferee?.refereeOneTypeId === 1 &&
            nominationReferee?.refereeOneFellow?.label && {
              label: nominationReferee.refereeOneFellow.label,
            }),
        },

        refereeTwoFellowId: {
          ...(nominationReferee?.refereeTwoTypeId === 1 &&
            nominationReferee?.refereeTwoFellow?.id && {
              value: nominationReferee.refereeTwoFellow.id,
            }),
          ...(nominationReferee?.refereeTwoTypeId === 1 &&
            nominationReferee?.refereeTwoFellow?.label && {
              label: nominationReferee.refereeTwoFellow.label,
            }),
        },

        refereeThreeFellowId: {
          ...(nominationReferee?.refereeThreeTypeId === 1 &&
            nominationReferee?.refereeThreeFellow?.id && {
              value: nominationReferee.refereeThreeFellow.id,
            }),
          ...(nominationReferee?.refereeThreeTypeId === 1 &&
            nominationReferee?.refereeThreeFellow?.label && {
              label: nominationReferee.refereeThreeFellow.label,
            }),
        },

        refereeFourFellowId: {
          ...(nominationReferee?.refereeFourTypeId === 1 &&
            nominationReferee?.refereeFourFellow?.id && {
              value: nominationReferee.refereeFourFellow.id,
            }),
          ...(nominationReferee?.refereeFourTypeId === 1 &&
            nominationReferee?.refereeFourFellow?.label && {
              label: nominationReferee.refereeFourFellow.label,
            }),
        },

        refereeFiveFellowId: {
          ...(nominationReferee?.refereeFiveTypeId === 1 &&
            nominationReferee?.refereeFiveFellow?.id && {
              value: nominationReferee.refereeFiveFellow.id,
            }),
          ...(nominationReferee?.refereeFiveTypeId === 1 &&
            nominationReferee?.refereeFiveFellow?.label && {
              label: nominationReferee.refereeFiveFellow.label,
            }),
        },

        refereeSixFellowId: {
          ...(nominationReferee?.refereeSixTypeId === 1 &&
            nominationReferee?.refereeSixFellow?.id && {
              value: nominationReferee.refereeSixFellow.id,
            }),
          ...(nominationReferee?.refereeSixTypeId === 1 &&
            nominationReferee?.refereeSixFellow?.label && {
              label: nominationReferee.refereeSixFellow.label,
            }),
        },

        refereeSevenFellowId: {
          ...(nominationReferee?.refereeSevenTypeId === 1 &&
            nominationReferee?.refereeSevenFellow?.id && {
              value: nominationReferee.refereeSevenFellow.id,
            }),
          ...(nominationReferee?.refereeSevenTypeId === 1 &&
            nominationReferee?.refereeSevenFellow?.label && {
              label: nominationReferee.refereeSevenFellow.label,
            }),
        },

        refereeEightFellowId: {
          ...(nominationReferee?.refereeEightTypeId === 1 &&
            nominationReferee?.refereeEightFellow?.id && {
              value: nominationReferee.refereeEightFellow.id,
            }),
          ...(nominationReferee?.refereeEightTypeId === 1 &&
            nominationReferee?.refereeEightFellow?.label && {
              label: nominationReferee.refereeEightFellow.label,
            }),
        },

        refereeNineFellowId: {
          ...(nominationReferee?.refereeNineTypeId === 1 &&
            nominationReferee?.refereeNineFellow?.id && {
              value: nominationReferee.refereeNineFellow.id,
            }),
          ...(nominationReferee?.refereeNineTypeId === 1 &&
            nominationReferee?.refereeNineFellow?.label && {
              label: nominationReferee.refereeNineFellow.label,
            }),
        },

        nonAasRefereeOneId:
          typeof nominationReferee?.refereeOneTypeId &&
          nominationReferee?.refereeOneTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeOne?.id,
                label: nominationReferee.nonAasRefereeOne?.label,
              }
            : null,

        nonAasRefereeTwoId:
          typeof nominationReferee?.refereeTwoTypeId === 'number' &&
          nominationReferee?.refereeTwoTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeTwo?.id,
                label: nominationReferee.nonAasRefereeTwo?.label,
              }
            : null,

        nonAasRefereeThreeId:
          typeof nominationReferee?.refereeThreeTypeId === 'number' &&
          nominationReferee?.refereeThreeTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeThree?.id,
                label: nominationReferee.nonAasRefereeThree?.label,
              }
            : null,

        nonAasRefereeFourId:
          typeof nominationReferee?.refereeFourTypeId === 'number' &&
          nominationReferee?.refereeFourTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeFour?.id,
                label: nominationReferee.nonAasRefereeFour?.label,
              }
            : null,

        nonAasRefereeFiveId:
          typeof nominationReferee?.refereeFiveTypeId === 'number' &&
          nominationReferee?.refereeFiveTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeFive?.id,
                label: nominationReferee.nonAasRefereeFive?.label,
              }
            : null,

        nonAasRefereeSixId:
          typeof nominationReferee?.refereeSixTypeId === 'number' &&
          nominationReferee?.refereeSixTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeSix?.id,
                label: nominationReferee.nonAasRefereeSix?.label,
              }
            : null,

        nonAasRefereeSevenId:
          typeof nominationReferee?.refereeSevenTypeId === 'number' &&
          nominationReferee?.refereeSevenTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeSeven?.id,
                label: nominationReferee.nonAasRefereeSeven?.label,
              }
            : null,

        nonAasRefereeEightId:
          typeof nominationReferee?.refereeEightTypeId === 'number' &&
          nominationReferee?.refereeEightTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeEight?.id,
                label: nominationReferee.nonAasRefereeEight?.label,
              }
            : null,

        nonAasRefereeNineId:
          typeof nominationReferee?.refereeNineTypeId === 'number' &&
          nominationReferee?.refereeNineTypeId === 0
            ? {
                value: nominationReferee.nonAasRefereeNine?.id,
                label: nominationReferee.nonAasRefereeNine?.label,
              }
            : null,

        nonPreferredReferee: nominationReferee?.nonPreferredReferee ?? null,
      };
      await axiosPut(`nominations/${nominationData.id}`, {
        data: {
          value: JSON.stringify(nominationFormData),
        },
      });

      return nominationFormData;
    }
    console.log('no response');
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
