import countryList from 'react-select-country-list';

export const getIndependentAssessorFormData = async (
  nominationReferee,
  organisations,
) => {
  try {
    const {
      refereeSevenTypeId,
      refereeEightTypeId,
      refereeNineTypeId,
      refereeSevenUserFellow,
      refereeEightUserFellow,
      refereeNineUserFellow,
      refereeSevenUserNonAas,
      refereeEightUserNonAas,
      refereeNineUserNonAas,
    } = nominationReferee;
    if (
      refereeSevenTypeId !== null ||
      refereeEightTypeId !== null ||
      refereeNineTypeId !== null
    ) {
      const createAssessorFellow = (fellowUser) =>
        fellowUser?.id
          ? { value: fellowUser.id, label: fellowUser.label }
          : null;

      const createOrganisation = (organisationId) =>
        organisationId
          ? { value: organisationId, label: organisations[organisationId] }
          : null;

      const createCountry = (countryId) =>
        countryId ? countryList().getLabel(countryId) : '';
      const getRefereeTypeId = (typeId) => {
        if (typeId !== 0 && typeId !== 1) {
          return 'none';
        }
        return typeId === 1 ? typeId : 0;
      };
      const getNonAasLabel = (item) => {
        const label = item.name
          ? `${item.name} ${item.lastName} (${item.email})`
          : `(${item.email})`;
        return label;
      };

      const refData = {
        nonAasRefereeSevenId: {
          value:
            nominationReferee.refereeSevenTypeId === 0
              ? nominationReferee?.refereeSevenUserNonAas?.id
              : null,
          label:
            nominationReferee.refereeSevenTypeId === 0
              ? getNonAasLabel(nominationReferee.refereeSevenUserNonAas)
              : null,
        },
        nonAasRefereeEightId: {
          value:
            nominationReferee.refereeEightTypeId === 0
              ? nominationReferee?.refereeEightUserNonAas?.id
              : null,
          label:
            nominationReferee.refereeEightTypeId === 0
              ? getNonAasLabel(nominationReferee.refereeEightUserNonAas)
              : null,
        },
        nonAasRefereeNineId: {
          value:
            nominationReferee.refereeNineTypeId === 0
              ? nominationReferee?.refereeNineUserNonAas?.id
              : null,
          label:
            nominationReferee.refereeNineTypeId === 0
              ? getNonAasLabel(nominationReferee.refereeNineUserNonAas)
              : null,
        },
        refereeSeven: getRefereeTypeId(refereeSevenTypeId),
        refereeEight: getRefereeTypeId(refereeEightTypeId),
        refereeNine: getRefereeTypeId(refereeNineTypeId),
        refereeSevenFellowId: createAssessorFellow(refereeSevenUserFellow),
        refereeEightFellowId: createAssessorFellow(refereeEightUserFellow),
        refereeNineFellowId: createAssessorFellow(refereeNineUserFellow),
        refereeSevenTitleId: refereeSevenUserNonAas?.titleId,
        refereeSevenName: refereeSevenUserNonAas?.name,
        refereeSevenLastName: refereeSevenUserNonAas?.lastName,
        refereeSevenEmail: refereeSevenUserNonAas?.email,
        refereeSevenOrganisation: createOrganisation(
          refereeSevenUserNonAas?.organisationId,
        ),
        refereeSevenCountryId: createCountry(refereeSevenUserNonAas?.countryId),
        refereeSevenMemberships: refereeSevenUserNonAas?.memberships,

        refereeEightTitleId: refereeEightUserNonAas?.titleId,
        refereeEightName: refereeEightUserNonAas?.name,
        refereeEightLastName: refereeEightUserNonAas?.lastName,
        refereeEightEmail: refereeEightUserNonAas?.email,
        refereeEightOrganisationId: createOrganisation(
          refereeEightUserNonAas?.organisationId,
        ),
        refereeEightCountryId: createCountry(refereeEightUserNonAas?.countryId),
        refereeEightMemberships: refereeEightUserNonAas?.memberships,

        refereeNineTitleId: refereeNineUserNonAas?.titleId,
        refereeNineName: refereeNineUserNonAas?.name,
        refereeNineLastName: refereeNineUserNonAas?.lastName,
        refereeNineEmail: refereeNineUserNonAas?.email,
        refereeNineOrganisationId: createOrganisation(
          refereeNineUserNonAas?.organisationId,
        ),
        refereeNineCountryId: createCountry(refereeNineUserNonAas?.countryId),
        refereeNineMemberships: refereeNineUserNonAas?.memberships,
      };
      return refData;
    }
    return null;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
