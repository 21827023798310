import html2pdf from 'html2pdf.js';

export const handleDownload = async (candidate, pdfUrls) => {
  const elements = document.querySelectorAll('.card');

  if (elements.length === 0) {
    console.error('No elements with class "card" found.');
    return;
  }

  const htmlContent = Array.from(elements)
    .map((element) => element.outerHTML)
    .join('<br><br>');

  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;
  tempElement.style.fontSize = '12px';
  tempElement.style.margin = '0';
  tempElement.style.padding = '20px';
  document.body.appendChild(tempElement);

  try {
    const options = {
      margin: 0,
      filename: `${candidate}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const pdf = html2pdf().from(tempElement).set(options).outputPdf('blob');

    const additionalPdfPromises = pdfUrls.map((url) =>
      fetch(url).then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to fetch additional PDF: ${response.statusText}`,
          );
        }
        return response.arrayBuffer();
      }),
    );

    const additionalPdfArrayBuffers = await Promise.all(additionalPdfPromises);

    const pdfBlob = await pdf;
    const pdfBytes = await pdfBlob.arrayBuffer();

    const { PDFDocument } = await import('pdf-lib');
    const [generatedPdfDoc, ...additionalPdfDocs] = await Promise.all([
      PDFDocument.load(pdfBytes),
      ...additionalPdfArrayBuffers.map((buffer) => PDFDocument.load(buffer)),
    ]);

    const newPdfDoc = await PDFDocument.create();
    const generatedPages = await newPdfDoc.copyPages(
      generatedPdfDoc,
      generatedPdfDoc.getPageIndices(),
    );
    generatedPages.forEach((page) => newPdfDoc.addPage(page));

    for (const additionalPdfDoc of additionalPdfDocs) {
      const additionalPages = await newPdfDoc.copyPages(
        additionalPdfDoc,
        additionalPdfDoc.getPageIndices(),
      );
      additionalPages.forEach((page) => newPdfDoc.addPage(page));
    }

    const newPdfBytes = await newPdfDoc.save();
    const newPdfBlob = new Blob([newPdfBytes], { type: 'application/pdf' });
    const newPdfUrl = URL.createObjectURL(newPdfBlob);

    const link = document.createElement('a');
    link.href = newPdfUrl;
    link.download = `${candidate}.pdf`;
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.error('Error generating PDF:', error);
  } finally {
    document.body.removeChild(tempElement);
  }
};
