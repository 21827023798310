import React, { useEffect, useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { axiosPut } from '../../utils/axiosPut';
import { windowScrollToTop } from '../../utils/windowScrollToTop';

import PageTitle from '../layouts/PageTitle';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';

const ChairCommittee = () => {
  const { activeFellowsArray, sectionalCommittees } = useStaticCollections();
  const { chairCommitteeArray } = useAdminCollections();

  const [notification, setNotification] = useState(null);
  const { control, setValue, handleSubmit, watch } = useForm();

  useEffect(() => {
    (async () => {
      try {
        chairCommitteeArray.forEach(({ id, attributes }) => {
          setValue(`chair-committee-${id}`, {
            value: attributes.fellowId,
            label: attributes.label,
          });
        });
      } catch (error) {
        console.error('Error in ChairCommittee:', error);
      }
    })();
  }, [chairCommitteeArray, setValue]);

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    try {
      for (const [id, selectedOption] of Object.entries(formData)) {
        const selectedFellow = activeFellowsArray.find(
          (fellow) => fellow.id === parseInt(selectedOption.value),
        );

        if (selectedFellow) {
          const fellowEmail = selectedFellow.attributes.email;
          const rowId = id.match(/\d+/)[0];

          await axiosPut(`chair-committees/${rowId}`, {
            data: {
              fellowId: parseInt(selectedOption.value),
              email: fellowEmail,
              label: selectedOption.label,
            },
          });
        }
      }
      setNotification('Updated');

      setTimeout(() => {
        setNotification('');
      }, 5000);

      windowScrollToTop();
    } catch (error) {
      console.error('Error updating emails:', error);
    }
  };

  const selectOptions = activeFellowsArray.map((item) => ({
    value: item.id,
    label: item.attributes.label,
  }));

  const selectedFellowIds = Object.entries(watch())
    .map(([, value]) => (value?.value ? parseInt(value.value) : null))
    .filter((value) => value !== null);

  const filteredOptions = selectOptions.filter(
    (option) => !selectedFellowIds.includes(option.value),
  );

  return (
    <>
      <PageTitle
        activeMenu="ChairCommittee"
        motherMenu="Home"
        notificationMessage={notification}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Chair committees</h4>
        </div>
        <div className="card-body text-left">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {Object.entries(sectionalCommittees).map(([id, label]) => (
                <Col key={id} xs={12} md={6}>
                  <div className="mb-xl-5">
                    <label
                      htmlFor={`chair-committee-${id}`}
                      className="form-label"
                    >
                      {label}
                    </label>
                    <Controller
                      name={`chair-committee-${id}`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filteredOptions}
                          onChange={(selectedOption) => {
                            setValue(`chair-committee-${id}`, selectedOption);
                          }}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: `3rem`,
                              borderRadius: '0.5rem',
                              borderColor: '#B3B3B3',
                            }),
                          }}
                          className="mt-1"
                        />
                      )}
                    />
                  </div>
                </Col>
              ))}
            </div>
            <Button variant="success" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChairCommittee;
