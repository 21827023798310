export const addCmseCommitteeToSectionalCommitteeArray = (
  sectionalCommitteeArray,
) => [
  ...sectionalCommitteeArray,
  {
    id: 14,
    attributes: {
      name: 'CMSE: Corresponding Member and Special Election Committee',
    },
  },
];
