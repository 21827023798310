import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { getNominationRoundYear } from '../../utils/getNominationRoundYear';

import { addCmseCommitteeToSectionalCommitteeArray } from '../../utils/addCmseCommitteeToSectionalCommitteeArray';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';

const SectionalCommittees = ({
  TableComponent,
  handleSelectClick,
  statusTypes,
}) => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { sectionalCommitteesArray } = useStaticCollections();
  const { nominationArray, chairCommitteeArray } = useAdminCollections();

  const [nominations, setNominations] = useState([]);
  const [sectionalCommitteeId, setSectionalCommitteeId] = useState(null);

  useEffect(() => {
    const currentYear = getNominationRoundYear();

    const shortlistedAndElectedNominations = nominationArray.filter((item) => {
      const publishedAtDate = item.attributes.publishedAt.slice(0, 10);
      const publishedAt = new Date(publishedAtDate);
      const publishedRoundYear = getNominationRoundYear(publishedAt);

      return (
        statusTypes.includes(item.attributes.nominationStatusTypeId) &&
        publishedRoundYear === currentYear
      );
    });

    setNominations(shortlistedAndElectedNominations);
  }, [nominationArray, statusTypes]);

  useEffect(() => {
    try {
      if (userDetails && userDetails?.fellowId && chairCommitteeArray) {
        const userChairCommittee = chairCommitteeArray.find(
          (item) => item.attributes.fellowId === userDetails.fellowId,
        );

        if (userChairCommittee && userChairCommittee.id) {
          setSectionalCommitteeId(userChairCommittee.id);
        }
      }
    } catch (error) {
      console.error('Error in NominationShortlist:', error);
    }
  }, [chairCommitteeArray, userDetails, nominationArray]);

  const result =
    (userDetails && userDetails?.roles?.includes('super-admin')) ||
    userDetails?.roles?.includes('admin') ||
    sectionalCommitteeId === null
      ? sectionalCommitteesArray
      : [
          sectionalCommitteesArray.find(
            (item) => item.id === sectionalCommitteeId,
          ),
        ];

  const sectionalCommitteesWithCmseCommittee =
    addCmseCommitteeToSectionalCommitteeArray(result);

  return (
    <>
      {sectionalCommitteesWithCmseCommittee.map((committee, indexCategory) => {
        const nominationsInSectionalCommittee = nominations.filter(
          (nomination) =>
            nomination.attributes.sectionalCommitteeId === committee.id ||
            (nomination.attributes.electionTypeId !== 2 &&
              !nomination.attributes.sectionalCommitteeId &&
              committee.id === 14),
        );

        if (nominationsInSectionalCommittee.length > 0) {
          return (
            <Row
              key={committee.id}
              className={indexCategory !== 0 ? 'mt-xl-5' : 'mb-xl-5'}
            >
              <Col>
                <div className="row page-titles">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      <h4 className="text-center">
                        {committee.attributes.name}
                      </h4>
                    </li>
                  </ol>
                </div>

                <TableComponent
                  nominationsInSectionalCommittee={
                    nominationsInSectionalCommittee
                  }
                  handleSelectClick={handleSelectClick}
                />
              </Col>
            </Row>
          );
        }

        return null;
      })}
    </>
  );
};

SectionalCommittees.propTypes = {
  TableComponent: PropTypes.elementType,
  statusTypes: PropTypes.array,
  handleSelectClick: PropTypes.func,
};

export default SectionalCommittees;
