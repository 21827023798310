import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ValidationIndependentAssessor from '../Validation/ValidationIndependentAssessor';
import WizardWrapper from './WizardWrapper';
import { axiosGet } from '../../../../utils/axiosGet';
import { axiosPost } from '../../../../utils/axiosPost';
import { CommonCollections } from '../../../../App';
import { useStaticCollections } from '../../../../context/StaticCollections';
import { useAdminCollections } from '../../../../context/AdminCollections';
import { getIndependentAssessorFormData } from '../../../../utils/getIndependentAssessorFormData';
import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';
import { formatIndependentAssessorData } from '../../../../utils/formatIndependentAssessorData';

const WizardIndependentAssessor = () => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { sectionalCommittees } = useStaticCollections();
  const { candidates, nominationArray } = useAdminCollections();
  const {
    nominationId,
    setIsFormSubmitted,
    refreshDynamicCollections,
    setRefreshDynamicCollections,
    organisations,
  } = useContext(CommonCollections);

  const { refreshRefereeReports, setRefreshRefereeReports } =
    useContext(CommonCollections);

  const [formState, setFormState] = useState({});
  const [formStatus, setFormStatus] = useState(1);
  const [refereeData, setRefereeData] = useState({});

  const {
    register,
    setValue,
    watch,
    control,
    resetField,
    setError,
    reset,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidationIndependentAssessor),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!nominationId) return;

      try {
        const { data: existingUsers } = await axiosGet(
          'independent-assessor-get-users',
          { nominationId },
        );
        setValue('existingUsers', JSON.stringify(existingUsers));

        const { data: nominationReferee } = await axiosGet(
          'get-independent-assessors',
          { data: nominationId },
        );

        if (
          nominationReferee.refereeSevenTypeId !== null ||
          nominationReferee.refereeEightTypeId !== null ||
          nominationReferee.refereeNineTypeId !== null
        ) {
          const refData = await getIndependentAssessorFormData(
            nominationReferee,
            organisations,
          );
          setFormStatus(1);
          Object.keys(refData).forEach((item) => {
            setValue(item, refData[item]);
          });
          trigger();
        }

        const nomination = nominationArray.find(
          (nom) => nom.id === nominationId,
        );
        const sectionalCommittee =
          sectionalCommittees[nomination.attributes.sectionalCommitteeId];
        const candidate = candidates[nomination.attributes.candidateId];

        setRefereeData({
          candidate,
          sectionalCommittee,
          electionTypeId: nomination.attributes.electionTypeId,
          testEmail: userDetails.email,
        });
        trigger();
      } catch (error) {
        console.error('Error setting independent assessor form values:', error);
      }
    };
    trigger();
    fetchData();
  }, [
    userDetails,
    nominationId,
    reset,
    setValue,
    trigger,
    candidates,
    nominationArray,
    sectionalCommittees,
    organisations,
  ]);

  const onSubmit = async (data) => {
    try {
      const independentAssessorData = formatIndependentAssessorData(data);
      const formattedData = {
        ...independentAssessorData,
        ...refereeData,
        nominationId,
      };

      const response = await axiosPost('independent-assessor-post', {
        data: formattedData,
      });

      if (response.status === 200) {
        setIsFormSubmitted(true);
        setRefreshRefereeReports(!refreshRefereeReports);
        setRefreshDynamicCollections(!refreshDynamicCollections);
      }
    } catch (error) {
      console.error('Error submitting independent assessor form:', error);
    }
  };

  return (
    <WizardWrapper
      activeMenu="Independent Assessor"
      motherMenu="Home"
      formName="independentAssessors"
      formState={formState}
      setFormState={setFormState}
      setValue={setValue}
      register={register}
      errors={errors}
      control={control}
      resetField={resetField}
      setError={setError}
      watch={watch}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      formStatusId={formStatus}
      trigger={trigger}
    />
  );
};

export default WizardIndependentAssessor;
