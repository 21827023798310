export const MenuList = [
  // Dashboard
  {
    title: 'Reports',
    classChange: 'mm-collapse',
    iconStyle: 'bi bi-grid',
    content: [
      {
        title: 'Nominations',
        to: 'nomination-reports',
      },
      {
        title: 'Short-lis1ted Candidates',
        to: 'short-listed-candidates',
      },
      {
        title: 'Corresponding Membership and Special Election',
        to: 'corresponding-and-special-elections',
      },
    ],
    access: ['super-admin', 'admin'],
  },

  // Nominations
  {
    title: 'Nominations',
    iconStyle: 'bi bi-list',
    to: 'nominations',
    access: ['fellow', 'super-admin'],
  },

  // Admin menu items
  {
    title: 'Nominations',
    iconStyle: 'bi bi-list',
    to: 'nomination-administration',
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Referee',
    iconStyle: 'bi bi-journals',
    to: 'referee-administration',
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Shortlisting',
    iconStyle: 'bi bi-star-fill',
    to: 'nomination-shortlisting',
    access: ['admin', 'chair', 'super-admin'],
  },
  {
    title: 'Assessor',
    iconStyle: 'bi bi-person-plus-fill',
    to: 'assessor-administration',
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Elect Candidates',
    iconStyle: 'bi bi-check',
    to: 'elect-candidate',
    access: ['admin', 'super-admin'],
  },

  // Filtering table
  {
    title: 'Administration',
    classChange: 'mm-collapse',
    iconStyle: 'bi bi-lock',
    content: [
      {
        title: 'Extension Requests',
        to: 'referee-report-extension-requests',
      },
      {
        title: 'Chair Committees',
        to: 'chair-committees',
      },
      {
        title: 'Masquerade',
        to: 'masquerade',
      },
    ],
    access: ['admin', 'super-admin'],
  },

  // Filtering table
  {
    title: 'My References',
    iconStyle: 'bi bi-journals',
    to: 'referee',
    access: ['referee', 'super-admin'],
  },
];
