import React from 'react';
import PropTypes from 'prop-types';

const ButtonIcon = ({
  handleClick,
  handleKeyPress,
  bootstrapIcon = 'bi-emoji-smile',
  backgroundColor = 'bg-success',
  tooltipText = '',
}) => (
  <button
    className="action-button-two"
    type="button"
    onClick={handleClick}
    onKeyPress={handleKeyPress}
    aria-label="Suspend nomination"
  >
    <span title={tooltipText}>
      <i className={`bi ${bootstrapIcon} action-button ${backgroundColor}`} />
    </span>
  </button>
);

ButtonIcon.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  bootstrapIcon: PropTypes.string,
  backgroundColor: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default ButtonIcon;
